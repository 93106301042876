
import { defineComponent, onMounted, ref, watch } from "vue";
import { getInstance, Embalagem } from "@/services/EmbalagemService";
import Cadastro from "./Cadastro.vue";
import { useConsulta, useExcluir } from "@/composables/crud";
import { formatDate } from "@/utils/format";

export default defineComponent({
  components: { Cadastro },
  setup() {
    const {
      data,
      loading,
      search,
      paginatorInfo,
      consultar,
    } = useConsulta<Embalagem>(getInstance());
    const { confirmarExcluir } = useExcluir(getInstance());
    const dialogCadastro = ref(false);
    const selecionado = ref<Embalagem>();

    search.sortField = "id";
    search.sortOrder = "DESC";

    watch(dialogCadastro, (v) => !v && (selecionado.value = undefined));

    onMounted(() => consultar());

    return {
      formatDate,
      dialogCadastro,
      data,
      selecionado,
      paginatorInfo,

      loading,
      search,
      consultar,
      onPage(event: any) {
        search.first = event.rows;
        search.page = event.page + 1;
      },
      onSort(event: any) {
        search.sortField = event.sortField;
        search.sortOrder = event.sortOrder > 0 ? "ASC" : "DESC";
      },

      editar(p: Embalagem) {
        selecionado.value = p;
        dialogCadastro.value = true;
      },

      async excluir(p: Embalagem) {
        (await confirmarExcluir(p.id)) && consultar();
      },
    };
  },
});
