<template>
  <p-dialog
    :header="`${id ? 'Editar' : 'Cadastrar'} Embalagem`"
    :visible="visible"
    @update:visible="updateDialogVisible"
    modal
    style="width: 500px"
  >
    <form class="form" @submit.prevent="salvar">
      <div class="p-fluid">
        <div class="p-field">
          <label>Descrição:</label>
          <p-inputtext v-model="data.descricao" required maxlength="30" />
        </div>
        <div class="p-field">
          <label>Altura (Em cm)</label>
          <p-inputnumber
            v-model="data.altura"
            required
            :minFractionDigits="1"
            :maxFractionDigits="1"
          />
        </div>
        <div class="p-field">
          <label>Largura (Em cm)</label>
          <p-inputnumber
            v-model="data.largura"
            required
            :minFractionDigits="1"
            :maxFractionDigits="1"
          />
        </div>
        <div class="p-field">
          <label>Comprimento (Em cm)</label>
          <p-inputnumber
            v-model="data.comprimento"
            required
            :minFractionDigits="1"
            :maxFractionDigits="1"
          />
        </div>
        <div class="p-field">
          <label>Cubagem (Em cm)</label>
          <p-inputnumber
            v-model="data.cubagem"
            required
            readonly
            :minFractionDigits="1"
            :maxFractionDigits="1"
          />
        </div>
        <div class="p-field">
          <label>Estoque</label>
          <p-inputnumber
            v-model="data.estoque"
            required
            :minFractionDigits="0"
            :maxFractionDigits="0"
          />
        </div>
        <div class="p-field">
          <label>Status:</label>
          <p-dropdown
            v-model="data.status"
            :options="status"
            placeholder="Selecione o status"
          />
        </div>
      </div>

      <div class="p-d-flex p-flex-row-reverse p-jc-start">
        <p-button
          label="Salvar"
          :icon="`pi pi-save${saving || loading ? ' p-spin' : ''}`"
          :disabled="saving || loading"
          type="submit"
        />
        <p-button
          label="Cancelar"
          class="p-button-secondary p-mr-2"
          @click="close"
        />
      </div>
    </form>
  </p-dialog>
</template>

<script lang="ts">
import {
  getInstance,
  Embalagem,
  initialEmbalagem,
} from "@/services/EmbalagemService";
import { defineComponent, PropType, watch } from "vue";
import { useCadastro } from "@/composables/crud";

const status = ["ativa", "inativa", "indisponível"];

export default defineComponent({
  emits: ["update:visible", "cadastrar"],
  props: {
    id: [Number, String] as PropType<number>,
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const {
      data,
      reset,
      salvar,
      loading,
      saving,
      load,
    } = useCadastro<Embalagem>(getInstance(), initialEmbalagem);

    watch(
      () => props.visible,
      (v) => !v && reset()
    );
    watch(
      () => props.id,
      (id) => id && load(id)
    );
    function close() {
      if (!loading.value) {
        emit("update:visible", false);
      }
    }

    watch(
      () => [data.altura, data.comprimento, data.largura],
      () => {
        data.cubagem = data.altura * data.comprimento * data.largura || 0;
      }
    );

    return {
      status,
      saving,
      loading,
      data,
      reset,

      close,
      async salvar() {
        if (await salvar(props.id)) {
          emit("cadastrar");
          close();
        }
      },

      updateDialogVisible(v: boolean) {
        if (!v && loading.value) {
          return;
        }
        emit("update:visible", v);
      },
    };
  },
});
</script>