import gql from "graphql-tag";
import { client } from "@/api/apollo";
import { CrudService, PageConsult, PageConsultInput } from "./Services";
import { baseURL } from "@/api/rest";
import { clone, filterKeys } from "@/utils/object";

let instance: EmbalagemService;

export interface Embalagem {
  id: number;
  descricao: string;
  createdAt?: Date;
  updatedAt?: Date;
  status: string;
  altura: number;
  largura: number;
  comprimento: number;
  cubagem: number;
  estoque: number;
}

export const initialEmbalagem: Readonly<Embalagem> = Object.freeze({
  id: null!,
  descricao: null!,
  createdAt: null!,
  updatedAt: null!,
  status: null!,
  altura: null!,
  largura: null!,
  comprimento: null!,
  cubagem: null!,
  estoque: null!,
});

export function getInstance(): EmbalagemService {
  if (!instance) {
    instance = new EmbalagemService();
  }
  return instance;
}

export default class EmbalagemService implements CrudService<Embalagem> {
  async consultar(
    variables: PageConsultInput,
  ): Promise<PageConsult<Embalagem>> {
    const result = await client
      .query({
        variables,
        query: gql`
          query (
            $q: String
            $first: Int!
            $page: Int!
            $sortField: String!
            $sortOrder: SortOrder!
          ) {
            embalagens(
              q: $q
              first: $first
              page: $page
              orderBy: {
                column: $sortField
                order: $sortOrder
              }
            ) {
              data {
                id
                descricao
                status
                updatedAt
                createdAt
                altura
                largura
                comprimento
                estoque
                cubagem
              }
              paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
              }
            }
          }
        `,
      });
    const { data: { embalagens } } = result;
    return embalagens;
  }

  private prepareData(input: Embalagem) {
    const data = filterKeys(clone(input), [
      "descricao",
      "status",
      "altura",
      "largura",
      "comprimento",
      "cubagem",
      "estoque",
    ]);
    return data;
  }

  async cadastrar(input: Embalagem): Promise<number> {
    const result = await client
      .mutate({
        variables: {
          input: this.prepareData(input),
        },
        mutation: gql`
          mutation($input: EmbalagemInput!) {
            createEmbalagem(input: $input)
          }
        `,
      });
    const { data: { createEmbalagem } } = result;
    return createEmbalagem;
  }

  async atualizar(id: number, data: Embalagem) {
    await client
      .mutate({
        variables: {
          id,
          input: this.prepareData(data),
        },
        mutation: gql`
          mutation($id: ID!, $input: EmbalagemInput!) {
            updateEmbalagem(id: $id input: $input)
          }
        `,
      });
  }

  async excluir(id: number) {
    await client
      .mutate({
        variables: {
          id,
        },
        mutation: gql`
          mutation($id: ID!) {
            deleteEmbalagem(id: $id)
          }
        `,
      });
  }

  async getById(id: number): Promise<Embalagem> {
    const result = await client
      .query({
        variables: { id },
        query: gql`
          query ($id: ID!) {
            embalagem(id: $id) {
              id
              createdAt
              updatedAt
              descricao
              status
              altura
              largura
              comprimento
              cubagem
              estoque
            }
          }
        `,
      });
    const { data: { embalagem } } = result;
    return embalagem;
  }
}
